<!-- 高压侧-馈线 -->
<template>
  <div id="feeder-line">
    <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>高压侧-馈线</span>
      </div>
      <div class="content">
        <ul class="info">
          <li>
            <div class="info-content">
              <div></div>
              <span>合位开入</span>
            </div>
            <div class="info-content">
              <div class="danger"></div>
              <span>跳位开入</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>远方/就地开入</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>弹簧未储能</span>
            </div>
          </li>
          <li>
            <div class="info-content">
              <div></div>
              <span>上隔离刀/工作位置</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>上隔离刀/工作位置</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>接地刀</span>
            </div>
          </li>
          <li class="special">
            <div>
              <div class="info-content">
                <div></div>
                <span>A/D故障</span>
              </div>
              <div class="info-content">
                <div></div>
                <span>开出回路故障</span>
              </div>
              <div class="info-content">
                <div class="danger"></div>
                <span>控制回路故障</span>
              </div>
            </div>
            <div style="margin-left: 140px">
              <div class="info-content">
                <div></div>
                <span>过负荷告警</span>
              </div>
              <div class="info-content">
                <div></div>
                <span>事故总信号</span>
              </div>
              <div class="info-content">
                <div></div>
                <span>预警总信号</span>
              </div>
            </div>
          </li>
          <li>
            <div class="info-content">
              <div></div>
              <span>电流Ⅰ段跳闸</span>
            </div>
            <div class="info-content">
              <div class="danger"></div>
              <span>电流Ⅱ段跳闸</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>零序Ⅰ段跳闸</span>
            </div>
            <div class="info-content">
              <div></div>
              <span>零序Ⅱ段跳闸</span>
            </div>
          </li>
        </ul>
        <el-table class="table" :data="tableData">
          <el-table-column
            align="center"
            prop="name"
            label="采集时间"
            width="175"
          >
          </el-table-column>
          <el-table-column align="center" prop="zero" label="A相电流">
          </el-table-column>
          <el-table-column align="center" prop="one" label="B相电流">
          </el-table-column>
          <el-table-column align="center" prop="two" label="C相电流">
          </el-table-column>
          <el-table-column align="center" prop="three" label="A相电压">
          </el-table-column>
          <el-table-column align="center" prop="four" label="B相电压">
          </el-table-column>
          <el-table-column align="center" prop="five" label="C相电压">
          </el-table-column>
          <el-table-column align="center" prop="six" label="有功功率">
          </el-table-column>
          <el-table-column align="center" prop="seven" label="无功功率">
          </el-table-column>
          <el-table-column align="center" prop="eight" label="功率因数">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
        type: [],
      },
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [
        {
          name: "2023-12-19 00.00.00",
          zero: "A相电流",
          one: "B相电流",
          two: "C相电流",
          three: "A相电压",
          four: "B相电压",
          five: "C相电压",
          six: "有功功率",
          seven: "无功功率",
          eight: "功率因数",
        },
        {
          name: "2023-12-19 00.00.00",
          zero: "A相电流",
          one: "B相电流",
          two: "C相电流",
          three: "A相电压",
          four: "B相电压",
          five: "C相电压",
          six: "有功功率",
          seven: "无功功率",
          eight: "功率因数",
        },
        {
          name: "2023-12-19 00.00.00",
          zero: "A相电流",
          one: "B相电流",
          two: "C相电流",
          three: "A相电压",
          four: "B相电压",
          five: "C相电压",
          six: "有功功率",
          seven: "无功功率",
          eight: "功率因数",
        },
      ],
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "label",
      },
      treeData: [
        {
          id: 1,
          label: "配电室",
          children: [
            {
              id: 11,
              label: "高压侧",
            },
            {
              id: 12,
              label: "低压侧",
            },
            {
              id: 13,
              label: "变压器",
            },
            {
              id: 14,
              label: "直流屏",
            },
          ],
        },
        {
          id: 2,
          label: "强变",
        },
        {
          id: 3,
          label: "柱变",
        },
        {
          id: 4,
          label: "强电间",
          children: [
            {
              id: 15,
              label: "高压侧",
            },
            {
              id: 16,
              label: "低压侧",
            },
            {
              id: 17,
              label: "变压器",
            },
            {
              id: 18,
              label: "直流屏",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    // this.drawChart();
  },

  methods: {
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
    },
    drawChart() {
      var chart = echarts.init(document.getElementById("chart"));
      const dataCount = 300;
      const data = generateData(dataCount);
      var option = {
        title: {
          text: "单位:",
          left: 10,
          textStyle: {
            color: "#aed6ff",
          },
        },

        legend: {
          data: ["能耗情况"],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        toolbox: {
          show: false,
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          bottom: 90,
          left: "4%",
          right: "3%",
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        xAxis: {
          data: data.categoryData,
          silent: false,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            // interval: 30,
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          splitArea: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          axisLabel: {
            color: "#aed6ff", //
          },
        },
        legend: {
          data: ["能耗情况"],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        backgroundColor: "#071635",
        series: [
          {
            name: "能耗情况",
            type: "line",
            data: data.valueData,
            // Set `large` for large data amount
            large: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(42, 101, 180, .8)",
                },
                {
                  offset: 1,
                  color: "rgb(42, 101, 180, 0)",
                },
              ]),
            },
          },
        ],
      };
      function generateData(count) {
        let baseValue = Math.random() * 10;
        let time = +new Date(2011, 0, 1);
        let smallBaseValue;
        function next(idx) {
          smallBaseValue =
            idx % 30 === 0
              ? Math.random() * 10
              : smallBaseValue + Math.random() * 500 - 250;
          baseValue += Math.random() * 20 - 10;
          return Math.max(0, Math.round(baseValue + smallBaseValue) + 1500);
        }
        const categoryData = [];
        const valueData = [];
        for (let i = 0; i < count; i++) {
          categoryData.push(echarts.format.formatTime("mm:ss", time, false));
          valueData.push(next(i).toFixed(2));
          time += 100;
        }
        return {
          categoryData: categoryData,
          valueData: valueData,
        };
      }

      chart.setOption(option);
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#feeder-line {
  width: 100%;
  min-height: 903px;
  min-width: 1621px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.info {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.info li {
  width: 250px;
  border-radius: 3px;
  background-color: rgba(5, 53, 125, 0.12);
  box-sizing: border-box;
  padding: 16px 0 8px 30px;
}
.info-content {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.info-content div {
  width: 12px;
  height: 12px;
  background-color: #14bf9b;
  border-radius: 50%;
  margin-right: 15px;
}
.info-content span {
  color: #d8e3ff;
  font-size: 14px;
}
.info .special {
  width: 520px;
  display: flex;
}
.info-content .danger {
  background-color: #d12300;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
</style>
